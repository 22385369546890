/* global Component */
class PrivacyPolicyPage extends Component{

    static  name() {
        return "privacyPolicy";
    }

    static componentName() {
        return "privacyPolicy";
    }

    getTemplate() {
        return `<div>
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container">
                      <div class="section-main mb-4">
                          <h5 class="text-center">Política de privacidad</h5>
                          <hr>
                          <div>
                              <p>
                                  Para poder utilizar el sitio de manera eficiente y segura, los usuarios deberán aportar entre otros, los siguientes datos: razón social, nombre y apellido, domicilio, cuenta de correo electrónico, documento de identidad, CUIT, sin los cuales se tornaría imposible brindar los servicios. Por tal motivo, se requiere que los datos ingresados por los usuarios sean verdaderos y exactos. Los datos recabados en los formularios correspondientes serán incorporados a la base general de clientes de Ofidirect S.A. (“Ofidirect”). La información personal que los usuarios ingresen en el sitio será tratada en forma confidencial y Ofidirect hará su mejor esfuerzo para proteger la privacidad de los mismos, de conformidad con lo dispuesto en la Ley Nº 25.326 de protección de datos personales. Para ello Ofidirect utiliza un servidor seguro con encriptación, que encripta los datos suministrados y evita su uso no autorizado, garantizando una operación segura. No obstante lo anterior, el usuario deberá tener en cuenta que internet no es un medio inexpugnable en cuanto a su seguridad. Cuando se ingresan datos y números correspondientes a tarjetas de crédito, estos son encriptados, asegurando así que se mantengan en total confidencialidad y no puedan ser vistos por otras personas. Esta información no es almacenada en ninguna de nuestras bases de datos.
                              </p>
                              <p>
                                  En consonancia con lo prescripto por la Ley Nº 25.326, los usuarios tendrán el derecho de acceder, actualizar y rectificar los datos ingresados cuando lo deseen. Si se solicitara la supresión de los datos, la misma implicará dejar de ser usuario del sistema y deberá hacerse por carta documento o presentación con firma certificada a nombre de Ofidirect S.A. (at.: Atención al Cliente) al domicilio sito en California 1955, Ciudad Autónoma de Buenos Aires. Cualquier usuario del sitio tendrá derecho a solicitar y obtener información sobre los datos personales que Ofidirect tenga en su base de datos, quedando Ofidirect obligado a proporcionar la información solicitada dentro de los diez días corridos de haber sido intimada fehacientemente. Los usuarios también podrán ejercer el derecho de rectificación, cuando los datos que se posean fueran incorrectos.
                              </p>
                              <p>
                                  Asimismo, los usuarios podrán requerir en cualquier momento la baja de su solicitud y la eliminación de su cuenta de la base de datos. Ofidirect garantiza a sus usuarios que utilizará los datos dentro de las pautas establecidas por la Ley 25.326 de protección de los datos personales. Ofidirect podrá ser relevado del deber de secreto por resolución judicial y cuando medien razones fundadas relativas a la seguridad pública, la defensa nacional o la salud pública.. En la medida en que la legislación y normas de procedimiento lo permitan, Ofidirect informará a los usuarios sobre estos requerimientos.
                                  <br>
                                  Al registrarse en el sitio y formar parte de la base de datos de clientes, los usuarios aceptan que Ofidirect se comunique con ellos por vía postal, telefónica o electrónica para enviar información que Ofidirect considere, a su exclusivo criterio, que pueda ser de su interés, incluyendo publicidad e información sobre ofertas y promociones. En caso de que los usuarios no deseen ser contactados con estos fines, podrán manifestárselo fehacientemente a Ofidirect, quien procederá a interrumpir este tipo de comunicaciones en el menor tiempo que le sea posible.
                              </p>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
              </div>`;
    }
}

PrivacyPolicyPage.registerComponent();
